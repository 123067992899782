<template>
  <div class="card card-animation-on-hover text-left">
    <router-link :to="{ path: this.link}" @click.native="clickLog">
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; ">
        <img
          v-lazy=" this.image + '?q=&w=150&h=100&fit=clamp'"
          style="margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);border-radius: 0"
          v-bind:alt="this.title"
          align="left"
          height="100px"
          width="150px"
        />
        <h5 style="font-size: 16px;font-weight: bold;margin-bottom: 2px">{{ this.title }}</h5>
        <div v-html="this.text">
        </div>
        <small>{{ this.subTitle }} </small>
        <br>
      </div>
    </router-link>

  </div>
</template>
<script>

export default {
  name: 'HorizontalCard',
  components: {},
  props: {
    title: {
      type: String,
      description: 'Card title',
      default: ''
    },
    alt: {
      type: String,
      description: 'Card title',
      default: 'Tulum Party'
    },
    subTitle: {
      type: String,
      description: 'Card subtitle',
      default: ''
    },
    label: {
      type: String,
      description: 'Card label',
      default: ''
    },
    text: {
      type: String,
      description: 'Card subtitle',
      default: undefined
    },
    align: {
      type: String,
      default: 'left',
      description: 'Text Alignment',
    },
    link: {
      type: String,
      description: 'Link on Action',
      default: ''
    },
    action: {
      type: String,
      description: 'Action',
      default: ''
    },
    actionLabel: {
      type: String,
      description: 'Card subtitle',
    },
    image: {
      type: String,
      description: 'Card subtitle',
    },
    width: {
      type: String,
      description: 'Card subtitle',
      default: "300"
    },
    height: {
      type: String,
      description: 'Card subtitle',
      default: "300"
    },
  },
  methods: {
    clickLog() {
      this.$rollbar.info("Horizontal Card Click", this.title);
    },
  },
};
</script>

