<template>
  <div>
    <div class="header text-center" style="margin-bottom: 5px">
      <div class="row">
        <div class="col-md-10 el-col-md-offset-1">
          <h1 class="tulum-party-title">FAQS</h1>

          <img
            v-lazy="faqsImg+'?w=300&h=250&fit=clamp'" width="300px" height="250px" style="border-radius: 300px">

          <p>Frequent Asked Questions</p>
          <h2>Unlock the party, explore smarty!</h2>
          <div class="intro-text">
            <p>Welcome to the <span class="bold-text">Cabo.Party FAQs</span>Our FAQs section is here to provide you with essential answers. Discover details
              about upcoming events, venue information, ticketing, dress codes, and more. </p>
          </div>

          <hr/>
          <div class="row">
            <div v-for="(category, idx) in categories"
                 class="card col-sm-4 card-animation-on-hover text-left"
                 style="padding: 20px"
                 :key="category+idx">
              <a :href="`#${category}`">
                <img v-lazy="faqIcon+'?w=30&h=30'" height="30px" width="30px">

                {{ category }}</a>
            </div>
          </div>
          <hr>
          <div class="row">

            <div v-for="(category, idx) in categories"
                 class="card col-xl-6"
                 :key="idx">
              <h3 class=" text-left"
                  :id="category">
                <img  v-lazy="faqIcon+'?w=30&h=30'" alt="faqs">
              {{ category }}</h3>
              <dl class="text-left" v-for="(faq, idx) in faqs[category]" :key="idx">
                <dt style="color: darkgray; !important;">
                  {{ faq.title }}
                </dt>
                <dd v-html="faq.content">
                </dd>
              </dl>
            </div>
          </div>
          <hr/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import HorizontalCard from "@/components/Cards/HorizontalCard";


export default {
  name: 'FAQ',
  metaInfo: () => ({
    title: 'TULUM',
    link: [{rel: 'canonical', href: 'https://www.tulum.party/tulum-traveler-frequent-questions'}],
    titleTemplate: '%s | Traveler FAQS & Recommendations',
    meta: [
      {name: 'description', content: 'Tulum Traveler FAQS'},
      {
        name: 'keywords',
        content: 'Tulum, ATM, Seaweed, Sargazo, Tickets, Reservations, Jungle Parties',
      },
    ],
  }),
  data: () => {
    return {
      filterBy: 'all',
      faqsImg: 'https://imgix.cosmicjs.com/57e387a0-69cf-11ee-a6aa-e94909ee7434-image.png',
      faqIcon: 'https://imgix.cosmicjs.com/3c6580f0-6c2c-11ee-ae6f-f1c9a9c95b9c-image.png',
      colors: [
        "#7FFFD4",  // Aquamarine
        "#a3ffd4",  // Complementary color 1
        "#66ffd4",  // Complementary color 2
        "#00d4a0",  // Complementary color 3
        "#ff7a7a"   // Complementary color 4
      ]
      ,
      icons: {
        party: '🎉',
        tickets: '🎟',
        clubs: '🍾',
        reservations: '📖',
        tulum: '🌴',
        places: '📍',
        travel: '🛬'
      }
    };

  },
  created() {
    this.$store.dispatch('getFaq');
  },
  components: {
  },
  computed: {
    faqs: {
      get() {
        return this.$store.state.faqs.reduce((group, faq) => {
          let c = faq.metadata ? faq.metadata.category.key : 'tulum';
          group[c] = group[c] ?? [];
          group[c].push(faq);
          return group;
        }, {});
      },
    },
    categories: {
      get() {
        return this.$store.state.faqs.map((faq) => {
          return faq.metadata ? faq.metadata.category.key : 'tulum';
        }).filter((x, i, a) => a.indexOf(x) === i).reverse();
      },
    },
  },
  methods: {
    setFilter(category) {
      this.filterBy = category;
    }
  },
};
</script>

